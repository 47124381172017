import React from "react"
import PropTypes from "prop-types"
import { formatDate } from "@src/utils/dates"
const DateValue = ({ date, format: formatString }) => {
  return date ? <>{formatDate(date, formatString)}</> : null
}
DateValue.propTypes = {
  date: PropTypes.number,
  format: PropTypes.string,
}
DateValue.defaultProps = {
  format: "PPP",
}
export default DateValue
