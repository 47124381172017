import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import { ImagePropTypes } from "@src/components/core-image"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import Currency from "@src/components/core-value-local-currency"

const TourImage = styled(ImageWrapper)`
  & > img {
    width: 175px;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  @media only screen and (${props => props.theme.screen.tablet.max}) {
    width: 175px;
    height: 12.6vh;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 175px;
    height: 12.6vh;
  }
`

const LeftImageWrap = styled.div`
  float: left;
  width: 31%;
  margin-right: 0.4vw;
  overflow-x: hidden;
  border-radius: 8px;
`

const RightDetails = styled.div`
  float: left;
  width: 66%;
`

const TopContainer = styled.div`
  flex-direection: row;
  border-bottom: 1px solid #dddddd;
`

const GreyText = styled.div`
  font-family: "system-ui";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #6b7280;
  margin-bottom: 8px;
`

const TourNameText = styled.div`
  font-family: "system-ui";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #26383b;
  margin-bottom: 8px;
`

const ItineraryText = styled.div`
  font-family: "system-ui";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #000000;
  margin-bottom: 24px;
`

const PriceContainer = styled.div`
  padding-top: 24px;
`

const PriceText = styled(Currency)`
  font-family: "system-ui";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 26px;
  align-items: center;
  color: #41c689;
  margin-top: 8px;
`

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 24px 10px;
  background-color: #fafafa;
  @media only screen and (${props => props.theme.screen.small.min}) {
    ${props => props.border};
  }
`
const StyledIconDetailContentTile = styled(IconDetailContentTile)`
  align-items: center;
  text-align: center;
  margin: 7px 0px;
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  font-family: "system-ui";
  font-style: normal;
  line-height: 20px;

  & > .icon {
    font-size: 23px;
    &.icon-star {
      padding-left: 2px;
    }
  }
  & > p,
  & * p {
    margin: 0 6px 0 0;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 10px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const ReviewDetailsContent = styled(StyledIconDetailContentTile)`
  color: #6a6a6a;
  margin: 5px 0 20px;
  & > span > a {
    text-decoration: none;
  }
`

const GrayIconDetailContentTile = styled(StyledIconDetailContentTile)`
  & > p {
    color: #26383b;
    font-size: 14px;
    font-weight: 500;
    font-family: "system-ui";
    font-style: normal;
    line-height: 20px;
  }
`
const StyledDetailIcons = styled.div``

const PriceAvailabilityCTA = ({
  border,
  tourDuration,
  difficulty,
  tourIcons,
  children,
  className,
  reviewsSummary,
  tourImage,
  tourStartLocation,
  tourName,
  tourLink,
  price,
}) => {
  const { days } = tourDuration || {}
  const pTagClassName = "p3"
  let difficultyName = "Easy"

  if (difficulty == 2) difficultyName = "Easy to Moderate"
  if (difficulty == 3) difficultyName = "Moderate"
  if (difficulty == 4) difficultyName = "Moderate to Challenging"
  if (difficulty == 5) difficultyName = "Challenging"

  return (
    <CTAContainer className={className} border={border}>
      <TopContainer>
        <LeftImageWrap>
          <Image lazyload={false} wrapper={TourImage} {...tourImage} />
        </LeftImageWrap>
        <RightDetails>
          <GreyText>
            <span>
              {days} days starting in {tourStartLocation}
            </span>
          </GreyText>

          <TourNameText>
            <span>{tourName}</span>
          </TourNameText>

          <ItineraryText>
            <span>
              <a href={tourLink} target="_blank" rel="noreferrer">
                See itinerary.
              </a>
            </span>
          </ItineraryText>
        </RightDetails>
      </TopContainer>
      <PriceContainer>
        <GreyText>Starting from</GreyText>
        <PriceText
          amount={price?.amountBase ? price?.amountBase : price?.amount}
          code={price?.currency?.code}
          showAbbr={true}
          discount={price?.discount}
          applyRounding={!price?.discount ? "auto" : false}
        />
      </PriceContainer>
      <StyledDetailIcons className="detail-points">
        {reviewsSummary && reviewsSummary.rating ? (
          <ReviewDetailsContent glyph="star" color="primary">
            <span>
              Rated {reviewsSummary.rating.toFixed(1)}
              <a href={tourLink} external>
                {" "}
                ({reviewsSummary.total} Reviews)
              </a>
            </span>
          </ReviewDetailsContent>
        ) : undefined}
        <GrayIconDetailContentTile glyph="weightlifter">
          <p className={pTagClassName}>
            {difficultyName} Difficulty ({difficulty}/5)
          </p>
        </GrayIconDetailContentTile>
        {tourIcons &&
          tourIcons.reduce((result, tourIcon, index) => {
            if (tourIcon.icon !== "time2") {
              result.push(
                <GrayIconDetailContentTile key={index} glyph={tourIcon.icon}>
                  <p className={pTagClassName}>{tourIcon.label}</p>
                </GrayIconDetailContentTile>
              )
            }
            return result
          }, [])}
      </StyledDetailIcons>
      {children}
    </CTAContainer>
  )
}

PriceAvailabilityCTA.propTypes = {
  border: PropTypes.string,
  tourDuration: PropTypes.object,
  price: PropTypes.object,
  difficulty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tourIcons: PropTypes.array,
  tourItinerary: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ),
  promotionPoints: PropTypes.bool,
  onAvailableDatesClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  reviewsSummary: PropTypes.shape({
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onRatingsClick: PropTypes.func,
  tourImage: PropTypes.shape({
    header: PropTypes.shape({ ...ImagePropTypes }),
  }),
  tourStartLocation: PropTypes.string,
  tourName: PropTypes.string,
  tourLink: PropTypes.string,
}
PriceAvailabilityCTA.defaultProps = {
  promotionPoints: true,
}

export default PriceAvailabilityCTA
