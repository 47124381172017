import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Icon from "@src/components/core-icon"
import HtmlContent from "@src/components/core-value-html"

const CircleDiv = styled.div`
  background: none;
  flex-shrink: 0;
  width: 16px;
  height: 16px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 23px;
    height: 23px;
  }
`

const IconStyle = css`
  & > .icon {
    font-size: 16px;
    ${props =>
      props.color === "yellow-black" || props.color === "blue-black"
        ? css`
            color: ${props.theme.black};
          `
        : props.color === "green"
        ? css`
            color: ${props.theme.green2};
          `
        : props.color === "red"
        ? css`
            color: ${props.theme.red2};
          `
        : props.color === "gray"
        ? css`
            color: #818181;
          `
        : props.color === "routes-teal"
        ? css`
            color: #629d94;
          `
        : props.color === "darkGray"
        ? css`
            color: #4f4f4f;
          `
        : props.color === "secondary"
        ? css`
            color: #26383b;
          `
        : props.color === "teal"
        ? css`
            color: #3a6968;
          `
        : undefined};
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .icon {
      font-size: 23px;
    }
  }
`
const ColoredCircle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.color === "yellow-black"
      ? props.theme.primary
      : props.color === "blue-black"
      ? props.theme.lightBlue
      : "none"};

  ${IconStyle}
`

const IconContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${IconStyle}
  & > ${CircleDiv}, & > .icon {
    margin-right: ${props =>
      props.size === "small"
        ? "5px"
        : props.size === "close-small"
        ? "0px"
        : "10px"};
  }
`

const StyledHTML = styled(HtmlContent)``

const IconDetailContentTile = ({
  glyph,
  details,
  children,
  className,
  color,
  size,
}) => {
  return (
    <IconContentContainer
      className={`${className || ""} core-icon-content`}
      color={color}
      size={size}
    >
      {color &&
      color !== "primary" &&
      color !== "green" &&
      color !== "red" &&
      color !== "gray" &&
      color !== "teal" &&
      color !== "routes-teal" &&
      color !== "darkGray" &&
      color !== "secondary" ? (
        <CircleDiv className="circle-div">
          <ColoredCircle color={color}>
            <Icon glyph={glyph} />
          </ColoredCircle>
        </CircleDiv>
      ) : (
        <Icon glyph={glyph} primary={color === "primary"} />
      )}
      {details && <StyledHTML html={details} />}
      {children}
    </IconContentContainer>
  )
}

IconDetailContentTile.propTypes = {
  glyph: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  details: PropTypes.node,
  color: PropTypes.oneOf([
    "primary",
    "yellow-black",
    "blue-black",
    "green",
    "red",
    "gray",
    "teal",
    "secondary",
    "darkGray",
    "routes-teal",
  ]),
  size: PropTypes.oneOf(["small", "medium", "close-small"]),
}

export default IconDetailContentTile
