import React, { useState, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import AffiliateReferrals, {
  createAffiliateOrder,
} from "@src/services/affiliates/referrals"
import GTM from "@src/services/gtm"
import { LocaleContext } from "@src/context/locale-context"
import ModalContainer from "@src/components/container-modal"
import Form, {
  useFormContext,
  FormGroup,
  FormActions,
} from "@src/components/form"
import FormFieldInput from "@src/components/form-field-input"
import FormFieldInputNumberButtons from "@src/components/form-field-input-number-buttons"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputTextarea from "@src/components/form-field-input-textarea"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldInputPhoneNumber from "@src/components/form-field-input-phone-number"
import FormFieldInputDatePicker from "@src/components/form-field-input-datepicker"
import FormFieldInputCheckbox from "@src/components/form-field-input-checkbox"
import FormFieldInputCountry from "@src/components/form-field-input-country"
import Button from "@src/components/core-button"
import { navigate } from "@src/components/core-link"
import { PricePropTypes } from "@src/graphql-fragments/prices"
import { formatDate } from "@src/utils/dates"
import { formatNumber } from "@src/utils/numbers"
import { FORM_SUBMIT_DATE_FORMAT } from "@src/utils/constants/forms"
import { SITE_DOMAIN } from "@src/utils/constants"

const StyledTermsLink = styled(Button)`
  margin-left: 10px;
`
const HiddenField = styled(FormFieldInput)`
  display: none;
`
const currentDate = new Date()

const StyledButton = styled(Button)`
  padding: 16px 36px 17px;
  @media only screen and (${props => props.theme.screen.small.max}) {
    width: 100%;
  }
`

const CaveatText = styled.p`
  font-family: "system-ui";
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 2vh;
  color: #108b59;
  margin-top: 24px;
  margin-bottom: 24px;
`

const TourBookingRequestForm = ({
  bookingTerms,
  availability,
  formStatus,
  productDetails,
}) => {
  const { setValue, trigger } = useFormContext()
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false)

  const toggleTermsAndConditions = useCallback(
    open => {
      if (open) {
        setIsTermsModalOpen(true)
      } else {
        setIsTermsModalOpen(false)
      }
    },
    [setIsTermsModalOpen]
  )
  const handleTermsAndConditionsAgreement = useCallback(
    accepted => {
      setValue("agree_terms_10adventures", accepted)
      trigger("agree_terms_10adventures")
      toggleTermsAndConditions(false)
    },
    [setValue, trigger, toggleTermsAndConditions]
  )
  const filterTravelDate = useCallback(
    checkDate => {
      if (availability) {
        if (
          availability.fixedDates &&
          availability.fixedDates.length &&
          !availability.fixedDates.some(check => {
            let departureDateParse

            // get departure date
            departureDateParse = check.start.split("-")
            if (departureDateParse.length !== 3) {
              return false
            }
            const departureDate = new Date()
            departureDate.setFullYear(
              departureDateParse[0],
              parseInt(departureDateParse[1]) - 1,
              departureDateParse[2]
            )
            departureDate.setHours(0, 0, 0, 0)

            return departureDate.getTime() === checkDate.getTime()
          })
        ) {
          return false
        } else if (
          availability.daysOfWeek &&
          !availability.daysOfWeek.includes(checkDate.getDay())
        ) {
          return false
        } else if (
          availability.months &&
          !availability.months.includes(checkDate.getMonth() + 1)
        ) {
          return false
        }
      }
      return checkDate > currentDate
    },
    [availability]
  )

  return (
    <>
      <FormGroup>
        <h2>Contact Information</h2>
        <p>
          {`Please share contact details for the person coordinating this booking.
        This person doesn't have to be the person who will pay for the tour.`}
        </p>
        <FormFieldInputText
          id="name-first"
          name="name_first"
          label="First name"
          autoComplete="given-name"
          placeholder="Enter first name"
          className="left narrow"
          required
          validation={{ required: "First name is required." }}
        />
        <FormFieldInputText
          id="name-last"
          name="name_last"
          label="Last name"
          autoComplete="family-name"
          placeholder="Enter last name"
          className="right narrow"
          required
          validation={{ required: "Last name is required." }}
        />
        <FormFieldInputCountry
          id="nationality"
          name="nationality"
          label="Nationality"
          placeholder="Select nationality"
          className="left narrow"
        />
        <FormFieldInputPhoneNumber
          id="phone-number"
          name="phone_number"
          label="Phone number"
          autoComplete="tel"
          className="right narrow"
        />
        <FormFieldEmailAddress
          id="email"
          name="email"
          label="Email"
          placeholder="Email address"
          confirmation
          required
          validation={{
            required: "Let us know where to contact you about your adventure.",
          }}
          autoComplete="email"
        />
        <FormFieldInputCheckbox
          id="subscribe-newsletter"
          name="subscribe_newsletter"
          label="Subscribe to our newsletter for free gear giveaways and discounts on adventure travel!"
        />
        <h2>Your trip</h2>
        <p>
          {`Please select your preferred tour start date for accurate pricing and availability. If your dates are unavailable, please select the closest possible start date and leave a note in the 'Special Requests' section below.`}
        </p>
        <FormFieldInputDatePicker
          id="travel-date"
          name="travel_date"
          icon="calendar-dates"
          label="Preferred travel start date"
          placeholder="Select a date"
          displayFormat="MMMM d, yyyy"
          filterDate={filterTravelDate}
          required
          validation={{
            required: "Please select your preferred start date.",
          }}
        />
        <FormFieldInputNumberButtons
          id="traveller-count"
          name="traveller_count"
          label="Number of travellers"
          min={1}
        />
        <FormFieldInputTextarea
          id="room-type-requests"
          name="details[room-type]"
          label="Room type"
          placeholder="Enter number of single/double/triple/other"
        />
        <FormFieldInputTextarea
          id="optional-extras"
          name="details[extras]"
          label="Special requests"
          placeholder="I.e. food allergies, additional activities, itinerary changes"
        />
        <h2>Have a coupon code?</h2>
        <FormFieldInputText
          id={`coupon-code`}
          name={`coupon-code`}
          label="Coupon code"
          placeholder="Enter coupon code"
        />

        {/* Hidden fields for netlify */}
        <HiddenField id="booking-tour-title" name="netlify_tour_title" />
        <HiddenField
          id="booking-tour-url"
          name="netlify_tour_url"
          value={`${SITE_DOMAIN}/${productDetails.id}/`}
        />
        <HiddenField name="netlify_selected_nationality" />
        <HiddenField name="netlify_requested_date" />
        <HiddenField name="netlify_traveller_count" />
        <HiddenField name="netlify_itinerary_start" />
        <HiddenField name="netlify_itinerary_end" />
        <HiddenField name="netlify_suggested_end_date" />
        <HiddenField name="netlify_affiliate_code" />
        <HiddenField name="booking_tour_operator" />
        <HiddenField name="booking_tour_cms_link" />
        {/* End of hidden fields for netlify */}
        <h2>Terms &amp; Conditions</h2>
        <p>
          To complete this booking request I acknowledge that I have read and
          accept the following:
        </p>
        <FormFieldInputCheckbox
          id="agree-terms-10adventures"
          name="agree_terms_10adventures"
          required
          onChange={handleTermsAndConditionsAgreement}
          validation={{
            validate: value =>
              value ||
              "You must agree to the terms and conditions to book this trip.",
          }}
        >
          <StyledTermsLink
            variant="plain"
            onClick={() => toggleTermsAndConditions(true)}
            title="Read the terms &amp; conditions for this booking"
          >
            10Adventures Terms &amp; Conditions
          </StyledTermsLink>
        </FormFieldInputCheckbox>
      </FormGroup>
      <ModalContainer
        id="tour-booking-terms-modal"
        isOpen={isTermsModalOpen}
        onClose={() => toggleTermsAndConditions(false)}
        title={bookingTerms && bookingTerms.title}
        content={bookingTerms && bookingTerms.content}
        variant="full-height"
        actions={
          <>
            <p>Do you agree to the 10Adventures Terms & Conditions?</p>
            <Button
              icon="close"
              size="small"
              color="outline"
              onClick={() => handleTermsAndConditionsAgreement(false)}
            >
              I do not agree
            </Button>
            <Button
              icon="check"
              size="small"
              onClick={() => handleTermsAndConditionsAgreement(true)}
            >
              I agree
            </Button>
          </>
        }
      />
      <FormActions
        submitting={formStatus && formStatus.processing ? true : undefined}
      >
        {(formStatus?.success && (
          <p className="form-success">{formStatus.success}</p>
        )) ||
          (formStatus?.error && (
            <p className="form-error">{formStatus.error}</p>
          )) ||
          (formStatus?.processing && (
            <p className="form-info">{formStatus.processing}</p>
          ))}
        <CaveatText>
          {`This is the start of the booking process. We'll review availability
          for your tour request & contact you within 24 hours.`}
        </CaveatText>
        <StyledButton
          type="submit"
          icon="calendar-dates"
          disabled={formStatus && formStatus.processing ? true : undefined}
        >
          Start your booking
        </StyledButton>
      </FormActions>
    </>
  )
}
TourBookingRequestForm.propTypes = {
  bookingTerms: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  availability: PropTypes.shape({
    daysOfWeek: PropTypes.arrayOf(PropTypes.number),
    fixedDates: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.string,
        finish: PropTypes.string,
      })
    ),
    months: PropTypes.arrayOf(PropTypes.number),
  }),
  formStatus: PropTypes.shape({
    processing: PropTypes.string,
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  productDetails: PropTypes.shape({
    id: PropTypes.string,
    price: PropTypes.shape({
      amount: PricePropTypes.amount,
      currency: PricePropTypes.currency,
    }),
    title: PropTypes.string,
    operator: PropTypes.string,
  }),
}

const TourBookingRequestFormWrapper = ({
  product,
  bookingTerms,
  availability,
}) => {
  const { currency: localeCurrency, convertCurrency } =
    useContext(LocaleContext)
  const [formStatus, setFormStatus] = useState(null)
  const generateBookingRequestDataLayer = useCallback(
    submitData => {
      const data = {}
      if (product) {
        if (product.id) {
          data.product = product.id
        }
        if (submitData.traveller_count) {
          data.guest_count = submitData.traveller_count
        }
        const quantity = data.guest_count || 1
        if (product.price) {
          data[`price_${process.env.GATSBY_ANALYTICS_CURRENCY}`] = parseFloat(
            formatNumber(
              convertCurrency(
                product.price.amount,
                product.price.currency.code,
                process.env.GATSBY_ANALYTICS_CURRENCY
              ),
              2,
              ""
            )
          )
          data[`total_${process.env.GATSBY_ANALYTICS_CURRENCY}`] =
            quantity * data[`price_${process.env.GATSBY_ANALYTICS_CURRENCY}`]

          if (
            localeCurrency &&
            localeCurrency.code &&
            localeCurrency.code !== process.env.GATSBY_ANALYTICS_CURRENCY
          ) {
            data[`price_${localeCurrency.code}`] = parseFloat(
              formatNumber(
                convertCurrency(
                  product.price.amount,
                  product.price.currency.code,
                  localeCurrency.code
                ),
                2,
                ""
              )
            )
            data[`total_${localeCurrency.code}`] =
              quantity * data[`price_${localeCurrency.code}`]
          }
        }
      }
      data["newsletter"] = submitData.subscribe_newsletter ? "yes" : "no"
      return data
    },
    [product, localeCurrency, convertCurrency]
  )
  const formatBookingRequestData = useCallback(
    data => {
      let netlifyEndDate
      let userNationality
      if (data.travel_date) {
        if (product.durationNights) {
          netlifyEndDate = new Date(data.travel_date) // avoid making changes to the same reference date value
          netlifyEndDate.setDate(
            data.travel_date.getDate() + product.durationNights
          )
          netlifyEndDate = formatDate(netlifyEndDate, FORM_SUBMIT_DATE_FORMAT)
        }
        data.travel_date = formatDate(data.travel_date, FORM_SUBMIT_DATE_FORMAT)
      }
      if (data.nationality) {
        data.nationality = data.nationality.value
        userNationality = data.nationality
      }

      const { details, ...values } = data
      const submitData = {
        product: (product && product.id) || "",
        currency: (localeCurrency && localeCurrency.code) || "",
        "booking-date": data.travel_date,
        ...values,
        // Provide values for the hidden fields sent to Netlify - need to be added here instead of in the hidden field as these load in as a result of a client query
        netlify_requested_date: data.travel_date,
        netlify_tour_title: product.title,
        netlify_traveller_count: data.traveller_count || 1,
        netlify_itinerary_start: product.itinerary.locationStart,
        netlify_itinerary_end: product.itinerary.locationEnd,
        netlify_suggested_end_date: netlifyEndDate,
        netlify_selected_nationality: userNationality,
        booking_tour_operator: product.operator,
        booking_tour_cms_link: `cms.10adventures.com/${product.id}`,
      }
      submitData["travellers-num"] = data.traveller_count || 1
      // travellers[0] is required due to how the back-end was set up to received the legacy booking request form data, which captured and generated this info for several travellers. In the future, this can be refactored along with a back-end change.
      const baseId = `travellers[0]`
      submitData[`${baseId}[firstName]`] = values["name_first"]
      submitData[`${baseId}[lastName]`] = values["name_last"]
      submitData[`${baseId}[phone]`] = values["phone_number"]
      submitData[`${baseId}[nationality]`] = values["nationality"]

      for (const detail in details) {
        submitData[`details[${detail}]`] = details[detail]
      }

      submitData["contact-email"] = data.email
      submitData["contact-email-confirm"] = data["email-confirm"]
      submitData["contact-promo"] = (data.subscribe_newsletter && "on") || ""

      submitData["agree-terms-10adventures"] =
        (data.agree_terms_10adventures && "on") || ""

      const refCode = AffiliateReferrals.getActiveReferral()

      if (refCode) {
        submitData.netlify_affiliate_code = refCode
      }
      return submitData
    },
    [localeCurrency, product]
  )
  const handleSubmit = useCallback(
    submitValues => {
      setFormStatus({
        processing: "Please wait a moment while we submit your request...",
      })
      const { subscribe_newsletter } = submitValues
      const dataLayer = generateBookingRequestDataLayer(submitValues)
      GTM.dataLayerPush({
        event: "10a.booking.success",
        ...dataLayer,
      })
      if (subscribe_newsletter) {
        GTM.dataLayerPush({
          event: "10a.subscription.success",
          subscribed: true,
        })
      }
      // refCode section creates order in GoAffPro
      const refCode = AffiliateReferrals.getActiveReferral()
      if (refCode) {
        createAffiliateOrder({
          order: {
            number: `Inquiry - ${product.title} - ${Date.now()}`,
            total: 0,
          },
          ref_code: refCode,
        })
      }
      setTimeout(function () {
        setFormStatus({
          success:
            "Thanks for the message! Our team will get back to you soon. Please contact us if you do not receive a booking confirmation.",
        })
        navigate("/bookings/thank-you/")
      }, "500")
    },
    [setFormStatus, generateBookingRequestDataLayer, product]
  )
  return (
    <Form
      name="booking-request-zoho"
      formatData={formatBookingRequestData}
      onSubmit={handleSubmit}
      netlify
    >
      <TourBookingRequestForm
        bookingTerms={bookingTerms}
        availability={availability}
        formStatus={formStatus}
        productDetails={product}
      />
    </Form>
  )
}
TourBookingRequestFormWrapper.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    price: PropTypes.shape({
      amount: PricePropTypes.amount,
      currency: PricePropTypes.currency,
    }),
    title: PropTypes.string,
    itinerary: PropTypes.shape({
      locationStart: PropTypes.string,
      locationEnd: PropTypes.string,
    }),
    durationNights: PropTypes.number,
    operator: PropTypes.string,
  }),
  bookingTerms: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  availability: PropTypes.shape({
    daysOfWeek: PropTypes.arrayOf(PropTypes.number),
    fixedDates: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.string,
        finish: PropTypes.string,
      })
    ),
    months: PropTypes.arrayOf(PropTypes.number),
  }),
}
export default TourBookingRequestFormWrapper
