export const CLIENT_QUERY_LOAD_TOUR_BY_SLUG = `query TourQuery($tourSlug: ID!)
{
  tour(id: $tourSlug, idType: SLUG) {
    databaseId
    slug
    title
    uri
    itinerary {
      locationStart
      locationEnd
    }
    difficulty: tourDifficulty
    duration: tourDuration {
      days
      nights
    }
    tourIcons {
      label
      icon
    }
    images {
      feature {
        src: url
        width
        height
        alt
        responsive {
          target
          sources {
            src: url
            width
            height
          }
        }
      }
    }
    price {
      amount
      amountBase
      currency {
        code
        symbol
      }
      discount {
        name
        value {
          amount
          type
        }
        dates {
          end
          start
        }
        calculated {
          amount
          currency {
            code
            symbol
          }
        }
      }
    }
    tourMonths
    dayofweekPrices {
      prices {
        day
        price {
          amount
          currency {
            code
            symbol
          }
        }
      }
    }
    fixedDates {
      start {
        date
      }
      finish {
        date
      }
    }
    tourOperator {
      id: databaseId
      name: title
      reviews {
        overall: overall_rating
        counts {
          total
        }
      }
    }
  }
}`
