import React from "react"
import styled from "styled-components"

const BannerContainer = styled.div`
  background: #26383b;
  width: 100%;
  padding: 40px 0;
`

const BannerHeader = styled.div`
  text-align: center;
  color: #fec51b;
  font-family: "system-ui";
  font-style: normal;
  font-weight: 900;
  font-size: max(16px, 2vw);
  line-height: 3.813vh;
  padding-top: 64px;
`
const StepContainer = styled.div`
  width: 80vw;
  margin: 0 auto;
  padding: 4vh 0;
  font-size: 12px;
  line-height: 14px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 0.9375vw;
    line-height: 1.049vh;
  }
  font-style: normal;
  font-family: "system-ui";
  color: #ffffff;
  padding-left: 1vw;
`

const StepItem = styled.div`
  width: 22%;
  float: left;
  text-align: center;
`

const StepHeader = styled.div`
  font-weight: 400;
  text-align: center;
  display: none;
  @media only screen and (${props => props.theme.screen.large.min}) {
    display: block;
  }
`

const StepText = styled.div`
  font-weight: 700;
  margin: 0 auto;
  margin-top: 1.5vh;
  text-align: center;
`

const StepArrow = styled.div`
  float: left;
  height: 2.29vh;
  width: 1.25vw;
  margin-top: 2.2vh;
  margin-left: 1vw;
  background: url("/icons/arrow-right-m.png") no-repeat;
  background-size: contain;
  // background-size: 1.15vh 0.625vw;
  @media only screen and (${props => props.theme.screen.small.min}) {
    background: url("/icons/arrow-right.png") no-repeat;
    background-size: contain;
    // background-size: 2.29vh 1.25vw;
    margin-top: 1.4vh;
    margin-leftt: 0;
  }
`

const ClearDiv = styled.div`
  clear: both;
  float: none;
  width: 0;
`

const BookingBanner = () => {
  return (
    <BannerContainer>
      <BannerHeader>Booking at 10Adventures</BannerHeader>
      <StepContainer>
        <StepItem>
          <StepHeader>Step 1</StepHeader>
          <StepText>Submit booking</StepText>
        </StepItem>
        <StepArrow></StepArrow>
        <StepItem>
          <StepHeader>Step 2</StepHeader>
          <StepText>Confirm availability</StepText>
        </StepItem>
        <StepArrow></StepArrow>
        <StepItem>
          <StepHeader>Step 3</StepHeader>
          <StepText>Pay deposit</StepText>
        </StepItem>
        <StepArrow></StepArrow>
        <StepItem>
          <StepHeader>Step 4</StepHeader>
          <StepText>Trip confirmed!</StepText>
        </StepItem>
        <ClearDiv></ClearDiv>
      </StepContainer>
    </BannerContainer>
  )
}

export default BookingBanner
