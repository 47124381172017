import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
const getColumnWidthMain = (maxWidth, columns) =>
  maxWidth - (columns ? columns.sideWidth : 0)
const getColumns = (variant, maxWidth, columns) => {
  switch (variant) {
    case "even": {
      const columnWidth = Math.round(maxWidth / 2)
      return `${columnWidth}px ${columnWidth}px`
    }
    case "right":
      return `${getColumnWidthMain(maxWidth, columns)}px ${columns.sideWidth}px`
    case "left":
    default:
      return `${columns.sideWidth}px ${getColumnWidthMain(maxWidth, columns)}px`
  }
}
const getColumnMargins = (variant, columns) => {
  switch (variant) {
    case "even":
      return css`
        & > *:first-child {
          margin-right: ${columns.spacing / 2}px;
        }
        & > *:nth-child(2) {
          margin-left: ${columns.spacing / 2}px;
        }
      `
    case "right":
      return css`
        & > *:nth-child(2) {
          margin-right: ${columns.spacing}px;
        }
      `
    case "left":
    default:
      return css`
        & > *:nth-child(2) {
          margin-left: ${columns.spacing}px;
        }
      `
  }
}

const ColumnsWrapper = styled.div`
  display: grid;
  & > *:first-child {
    grid-row: 1 / 2;
  }
  & > *:nth-child(2) {
    grid-row: 2 / 3;
  }
  & > * {
    grid-column: 2 / -2;
  }
  grid-template-columns: minmax(1rem, 1fr) minmax(auto, calc(100vw - 3rem)) minmax(
      1rem,
      1fr
    );

  @media only screen and (${props => props.theme.screen.medium.min}) {
    ${props =>
      props.variant === "right"
        ? css`
            & > *:first-child {
              grid-column: 3 / 4;
              align-self: start;
            }
            & > *:nth-child(2) {
              grid-row: 1 / 2;
              grid-column: 2 / 3;
            }
          `
        : css`
            & > *:first-child {
              grid-column: 2 / 3;
              align-self: start;
            }
            & > *:nth-child(2) {
              grid-row: 1 / 2;
              grid-column: 3 / 4;
            }
          `}
    grid-template-columns:
      minmax(1rem, 1fr)
      ${props =>
      getColumns(
        props.variant,
        props.theme.screen.medium.contentMaxWidths,
        props.columns?.medium || props.theme.screen.medium.contentColumns
      )}
      minmax(1rem, 1fr);
    ${props =>
      getColumnMargins(
        props.variant,
        props.columns?.medium || props.theme.screen.medium.contentColumns
      )}
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    grid-template-columns:
      minmax(1rem, 1fr)
      ${props =>
        getColumns(
          props.variant,
          props.theme.screen.large.contentMaxWidths,
          props.columns?.large || props.theme.screen.large.contentColumns
        )}
      minmax(1rem, 1fr);
    ${props =>
      getColumnMargins(
        props.variant,
        props.columns?.large || props.theme.screen.large.contentColumns
      )}
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    grid-template-columns:
      minmax(1rem, 1fr)
      ${props =>
        getColumns(
          props.variant,
          props.theme.screen.desktop.contentMaxWidths,
          props.columns?.desktop || props.theme.screen.desktop.contentColumns
        )}
      minmax(1rem, 1fr);
    ${props =>
      getColumnMargins(
        props.variant,
        props.columns?.desktop || props.theme.screen.desktop.contentColumns
      )}
  }
`
const ResponsiveColumns = ({ children, variant, columns, className }) => {
  return (
    <ColumnsWrapper variant={variant} columns={columns} className={className}>
      {children}
    </ColumnsWrapper>
  )
}
ResponsiveColumns.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["left", "right", "even"]),
  className: PropTypes.string,
  columns: PropTypes.shape({
    medium: PropTypes.shape({
      sideWidth: PropTypes.number,
      spacing: PropTypes.number,
    }),
    large: PropTypes.shape({
      sideWidth: PropTypes.number,
      spacing: PropTypes.number,
    }),
    desktop: PropTypes.shape({
      sideWidth: PropTypes.number,
      spacing: PropTypes.number,
    }),
  }),
}
export default ResponsiveColumns
