export { format as formatDate, parseISO as parseDateISO } from "date-fns"

// @see: https://date-fns.org/v2.0.0-alpha.18/docs/format
const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]
export const weekdayToNumber = weekday => weekdays.indexOf(weekday)
