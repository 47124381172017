import axios from "axios"
import { awsBasePath, graphql2Endpoint } from "../_config"
import { imageObjConstructor } from "../_utils"
import { CLIENT_QUERY_LOAD_TOUR_BY_SLUG } from "./queries"

const structureResultData = tour => {
  const result = {
    title: tour.title,
    uri: tour.url,
    content: tour.description,
    tourMonths: tour.monthsAvailable,
    carousel: tour.carouselImageDetails && {
      imageFront: imageObjConstructor(tour.carouselImageDetails),
    },
    map: tour.mapImageDetails && {
      image: imageObjConstructor(tour.mapImageDetails),
    },
    tourDuration: tour.duration && { days: tour.duration },
    tourRegions: tour.regions,
    tourCategories: tour.categories,
    tourTypes: tour.tourTypes,
    price: tour.priceDetails && JSON.parse(tour.priceDetails),
  }
  if (result.price && result.price.discount) {
    if (result.price.discount.calculated) {
      result.price.discount.calculated.amount = parseFloat(
        result.price.discount.calculated.amount
      )
    }
    if (result.price.discount.value) {
      result.price.discount.value.amount = parseFloat(
        result.price.discount.value.amount
      )
    }
  }
  return result
}

const TourContentService = {
  getTourBySlug: tourSlug =>
    axios
      .post(
        graphql2Endpoint,
        JSON.stringify({
          query: CLIENT_QUERY_LOAD_TOUR_BY_SLUG,
          variables: {
            tourSlug,
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(response => {
        if (response && response.data?.data?.tour) {
          return response.data.data.tour
        }
        return null
      }),
  getFilteredList: (params, { page, perPage } = {}, requestKey) =>
    axios({
      method: "post",
      url: `${awsBasePath}/es/tour-filter`,
      data: {
        params,
        pagination: {
          page: page || 1,
          perPage: perPage || 10,
        },
      },
    })
      .then(response => {
        if (!response || !response.data || !response.data.items) {
          return
        }
        return {
          requestKey,
          items: response.data.items.map(structureResultData),
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total,
          pageCount: Math.ceil(response.data.total / response.data.perPage),
          firstIndex: (response.data.page - 1) * response.data.perPage,
          lastIndex: 0,
        }
      })
      .catch(error => console.log(error)),
}
export default TourContentService
