import { graphql } from "gatsby"
import PropTypes from "prop-types"

export const CurrencyPropTypes = {
  code: PropTypes.string,
  symbol: PropTypes.string,
}

export const PricePropTypes = {
  amount: PropTypes.number,
  amountBase: PropTypes.number,
  currency: PropTypes.shape({ ...CurrencyPropTypes }),
  discount: PropTypes.shape({
    name: PropTypes.string,
    source: PropTypes.string,
    calculated: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.shape({
        ...CurrencyPropTypes,
      }),
      dates: PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
      }),
      value: PropTypes.shape({
        amount: PropTypes.number,
        type: PropTypes.string,
      }),
    }),
  }),
}

export const CurrencyFragment = graphql`
  fragment CurrencyFragment on TenToursGQL_Currency {
    code
    symbol
  }
`

export const PriceFragment = graphql`
  fragment PriceFragment on TenToursGQL_DiscountedPrice {
    amount
    amountBase
    currency {
      ...CurrencyFragment
    }
    discount {
      name
      source
      calculated {
        amount
        currency {
          ...CurrencyFragment
        }
      }
      dates {
        end
        start
      }
      value {
        amount
        type
      }
    }
  }
`
