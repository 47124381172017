import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "@src/components/core-button"
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`
const StyledValue = styled.span`
  display: inline-block;
  padding: 0 10px;
  min-width: 42px;
  text-align: center;
  font-weight: bold;
  @media only screen and (${props => props.theme.screen.small.min}) {
    min-width: 54px;
    font-size: 1.25em;
  }
`
const InputNumberButtons = ({
  className,
  id,
  name,
  value: defaultValue,
  onChange,
  min,
  max,
}) => {
  const [value, setValue] = useState(defaultValue || null)
  const updateValue = useCallback(
    newValue => {
      if (newValue < min) {
        newValue = min
      } else if (newValue > max) {
        newValue = max
      }
      setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [setValue, min, max, onChange]
  )
  useEffect(() => {
    if (updateValue) {
      updateValue(defaultValue)
    }
  }, [defaultValue, updateValue])
  return (
    <InputWrapper className={className} id={id}>
      {(value || value === 0) && (
        <input type="hidden" name={name} value={value} />
      )}
      <Button
        size="small"
        variant="circle"
        icon="minus"
        onClick={() => {
          updateValue(value - 1)
        }}
      />
      <StyledValue>{value}</StyledValue>
      <Button
        size="small"
        variant="circle"
        icon="plus"
        onClick={() => {
          updateValue(value + 1)
        }}
      />
    </InputWrapper>
  )
}
InputNumberButtons.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
}
export default InputNumberButtons
