import axios from "axios"
import Auth from "@src/services/user/auth"
import { awsBasePath } from "./_config"

const TourOperatorReviewsService = {
  getOperatorReviews: async (operatorId, { page }) =>
    axios
      .get(`${awsBasePath}/operator/${operatorId}/reviews?&page=${page}`)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        return null
      })
      .catch(error =>
        console.log("Error in getOperatorReviews request: ", error)
      ),

  getOperatorRatings: async operatorId =>
    axios
      .get(`${awsBasePath}/operator/${operatorId}/ratings`)
      .then(response => {
        if (response && response.data) {
          return response.data
        }
        return null
      })
      .catch(error =>
        console.log("Error in getOperatorReviews request: ", error)
      ),

  createOperatorReview: async (operatorId, values) => {
    const submitData = {
      reviewText: (values && values["review-content"]) || "",
      ratings: {
        overall: values.ratings.overall,
      },
    }

    // get the user's token to use for authorization
    const userToken = await Auth.getUserToken()
    return axios.post(
      `${awsBasePath}/operator/${operatorId}/reviews/`,
      submitData,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
  },
}
export default TourOperatorReviewsService
