import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import { formatDate as formatDateUtil } from "@src/utils/dates"
import FormatDate from "@src/components/core-value-date"
import Icon from "@src/components/core-icon"
const StyledIcon = styled(Icon)`
  &.icon {
    font-size: 18px;
    margin: -6px 10px 0 0;
  }
`
const StyledButton = styled.button`
  cursor: pointer;
  background-color: ${props => props.theme.bg1};
  color: ${props => props.theme.lightGray};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 4px;

  padding: 0 30px 0 10px;
  font-size: 16px;
  line-height: 15px;
  height: 34px;
  width: 100%;
  text-align: left;

  position: relative;
  display: inline-block;
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 0;
    top: 14px;
    right: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid ${props => props.theme.lightGray};
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 0 34px 0 12px;
    font-size: 16px;
    line-height: 20px;
    height: 42px;
    font-weight: 500;
    &::after {
      top: 18px;
    }
  }
`
const DatePickerDate = styled.span`
  position: relative;
  display: inline-block;
  width: 24px;
  padding: 3px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  border-radius: 8px;
  transition: all 0.1s ease-in;
`
const InputWrapper = styled.div`
  .react-datepicker {
    width: 420px;
    max-width: 100%;
  }
  .react-datepicker-popper {
    z-index: ${props => props.theme.layers.activeUI};
    width: 376px;
    max-width: 100vw;
    padding: 10px 20px;
    background-color: ${props => props.theme.bg1};
    border-radius: 10px;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .react-datepicker__day-names,
  .react-datepicker__week,
  .react-datepicker__month-wrapper,
  .react-datepicker__year-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    flex: 1 1 100%;
    padding: 9px 8px 8px;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    color: ${props => props.theme.darkGray};
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__year-text {
    cursor: pointer;
    flex: 1 1 100%;
    text-align: center;
  }
  .react-datepicker__day {
    padding: 8px 0 7px;
    height: 39px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      opacity: 0;
      left: 0;
      right: 100%;
      top: 8px;
      height: 24px;
      background-color: ${props => props.theme.primary};
      transition: all 0.1s ease-in;
    }
    &.react-datepicker__day--range-start::before,
    &.react-datepicker__day--selecting-range-start::before {
      left: 50%;
    }
    &.react-datepicker__day--range-end::before,
    &.react-datepicker__day--selecting-range-end::before {
      right: 50%;
    }
  }
  .react-datepicker__year-wrapper {
    flex-wrap: wrap;
  }
  .react-datepicker__month-text,
  .react-datepicker__year-text {
    font-size: 16px;
    line-height: 18px;
    border-radius: 8px;
    padding: 3px 11px;
  }
  .react-datepicker__month-text {
    flex: 0 1 27%;
    margin: 8px 0 7px;
  }
  .react-datepicker__year-text {
    flex: 0 1 50px;
    margin: 17px 19px 16px;
  }
  .react-datepicker__day--disabled {
    cursor: default;
    & ${DatePickerDate} {
      color: ${props => props.theme.lightGray};
    }
  }
  .react-datepicker__day--today {
    & ${DatePickerDate} {
      font-weight: bold;
      border: 1px solid ${props => props.theme.darkGray};
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
    & ${DatePickerDate} {
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.fg2};
      font-weight: bold;
    }
  }
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.fg2};
    font-weight: bold;
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    &::before {
      opacity: 1;
      right: 0;
    }
  }
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    ${DatePickerDate} {
      box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .react-datepicker__month--selecting-range {
    &
      .react-datepicker__day--range-start:not(.react-datepicker__day--selecting-range-start)::before {
      left: 0;
    }
    &
      .react-datepicker__day--range-end:not(.react-datepicker__day--selecting-range-end)::before {
      right: 0;
    }
    &
      .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
      &::before {
        opacity: 0;
        right: 100%;
      }
      ${DatePickerDate} {
        background-color: transparent;
        color: inherit;
        font-weight: normal;
      }
    }
  }
`

// DatePickerHeader - header of the datepicker
const HeaderDateBar = styled.div`
  display: flex;
  justify-content: space-between;
`
const HeaderDate = styled.span`
  font-size: 24px;
  line-height: 32px;
`
const HeaderButton = styled.button`
  background-color: transparent;
  border: 0 none transparent;
  cursor: pointer;
`
const HeaderMonth = styled(HeaderButton)`
  color: ${props => props.theme.black};
  font-weight: bold;
`
const HeaderYear = styled(HeaderButton)`
  color: ${props => props.theme.primary};
  font-weight: bold;

  ${HeaderMonth} + & {
    margin-left: 6px;
  }
`
const HeaderNav = styled.span``
const HeaderNavButton = styled(HeaderButton)`
  color: ${props => props.theme.black};
  width: 24px;
  height: 24px;
  margin-left: 20px;

  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;

  & .icon {
    font-size: 24px;
  }

  &:hover {
    color: ${props => props.theme.primary};
  }
`
const DatePickerHeader = ({
  mode,
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  setModeMonth,
  setModeYear,
}) => {
  const navYears = mode === "Y" || mode === "M"
  return (
    <HeaderDateBar>
      <HeaderDate>
        <HeaderMonth type="button" onClick={setModeMonth}>
          <FormatDate date={date.getTime()} format="MMMM" />
        </HeaderMonth>
        <HeaderYear type="button" onClick={setModeYear}>
          <FormatDate date={date.getTime()} format="yyyy" />
        </HeaderYear>
      </HeaderDate>
      <HeaderNav>
        <HeaderNavButton
          type="button"
          onClick={navYears && decreaseYear ? decreaseYear : decreaseMonth}
        >
          <Icon glyph="chevron-left" />
        </HeaderNavButton>
        <HeaderNavButton
          type="button"
          onClick={navYears && increaseYear ? increaseYear : increaseMonth}
        >
          <Icon glyph="chevron-right" />
        </HeaderNavButton>
      </HeaderNav>
    </HeaderDateBar>
  )
}
DatePickerHeader.propTypes = {
  date: PropTypes.object,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  decreaseYear: PropTypes.func,
  increaseYear: PropTypes.func,
  mode: PropTypes.string,
  setModeDate: PropTypes.func,
  setModeMonth: PropTypes.func,
  setModeYear: PropTypes.func,
}

// DatePickerInput - how the input appears when datepicker is closed
const DatePickerInput = React.forwardRef(
  ({ className, displayValue, icon, placeholder, onClick }, ref) => {
    const handleClick = event => {
      event.preventDefault()
      if (onClick) {
        onClick()
      }
    }
    return (
      <StyledButton
        type="button"
        ref={ref}
        className={className}
        onClick={handleClick}
      >
        {icon && <StyledIcon glyph={icon} />}
        {displayValue || placeholder}
      </StyledButton>
    )
  }
)
DatePickerInput.displayName = "DatePickerInput"
DatePickerInput.propTypes = {
  className: PropTypes.string,
  displayValue: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  setDate: PropTypes.func,
}

// InputDatePicker - the main component
const InputDatePicker = ({
  value: selectedDate,
  startDate,
  endDate,
  filterDate,
  placeholder,
  selectsRange,
  displayFormat,
  icon,
  className,
  onChange,
  shouldCloseOnSelect,
  ...props
}) => {
  const [value, setValue] = useState(
    selectsRange ? [startDate, endDate] : selectedDate
  )
  const [displayValue, setDisplayValue] = useState("")
  const [mode, setMode] = useState(null)
  const formatDate = useCallback(
    date => formatDateUtil(date, displayFormat),
    [displayFormat]
  )
  const handleChange = value => {
    switch (mode) {
      case "Y":
      case "M":
        break
      default:
        setValue(value)
        if (onChange) {
          onChange(value)
        }
        break
    }
    if (mode === "Y") {
      setMode("M")
    } else {
      setMode()
    }
  }
  useEffect(() => {
    setValue(selectsRange ? [startDate, endDate] : selectedDate)
  }, [selectedDate, startDate, endDate, selectsRange, setValue])
  useEffect(() => {
    if (selectsRange) {
      setDisplayValue(
        `${(startDate && formatDate(startDate)) || ""} to ${
          (endDate && formatDate(endDate)) || ""
        }`
      )
    } else if (value) {
      setDisplayValue(formatDate(value))
    }
  }, [value, startDate, endDate, selectsRange, formatDate])
  const setModeDate = () => setMode(null)
  const setModeMonth = () => {
    if (mode === "M" || mode === "Y") {
      setModeDate()
    } else {
      setMode("M")
    }
  }
  const setModeYear = () => {
    if (mode === "Y") {
      setMode("M")
    } else {
      setMode("Y")
    }
  }
  return (
    <InputWrapper>
      <DatePicker
        customInput={
          <DatePickerInput
            className={className}
            displayValue={displayValue}
            icon={icon}
            placeholder={placeholder}
          />
        }
        renderCustomHeader={headerProps => (
          <DatePickerHeader
            mode={mode}
            setModeDate={setModeDate}
            setModeMonth={setModeMonth}
            setModeYear={setModeYear}
            {...headerProps}
          />
        )}
        renderDayContents={day => <DatePickerDate>{day}</DatePickerDate>}
        formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
        placeholderText={placeholder}
        onChange={handleChange}
        showFullMonthYearPicker
        showMonthYearPicker={mode === "M"}
        showYearPicker={mode === "Y"}
        selected={!selectsRange ? value : value[0]}
        selectsRange={selectsRange}
        startDate={selectsRange ? startDate : value}
        endDate={selectsRange ? endDate : value}
        filterDate={filterDate}
        shouldCloseOnSelect={
          mode === "Y" || mode === "M" ? false : shouldCloseOnSelect
        }
        {...props}
      />
    </InputWrapper>
  )
}
InputDatePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  filterDate: PropTypes.func,
  onChange: PropTypes.func,
  selectsRange: PropTypes.bool,
  displayFormat: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  shouldCloseOnSelect: PropTypes.bool,
}
InputDatePicker.defaultProps = {
  displayFormat: "PP",
}
export default InputDatePicker
