import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
const Textarea = styled.textarea`
  background-color: ${props => props.theme.bg1};
  color: ${props => props.theme.darkGray};
  border: 1px solid ${props => props.theme.darkGray};
  border-radius: 4px;

  padding: 9px 10px;
  font-size: 16px;
  line-height: 20px;
  width: 100%;

  @media only screen and (${props => props.theme.screen.small.min}) {
    padding: 11px 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }
`
const InputTextarea = ({ value, register, validation, ...props }) => {
  return (
    <Textarea ref={register && register(validation)} {...props}>
      {value}
    </Textarea>
  )
}
InputTextarea.propTypes = {
  value: PropTypes.string,
  register: PropTypes.func,
  validation: PropTypes.object,
}
export default InputTextarea
