import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import ErrorPage404 from "@src/pages/404"
import GTM from "@src/services/gtm"
import TourContent from "@src/services/content/tours"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import { ImagePropTypes } from "@src/components/core-image"
import Spinner from "@src/components/core-spinner"
import PriceAvailability from "@src/components/content-single-tour-prices-availability"
import { weekdayToNumber } from "@src/utils/dates"
import { SITE_DOMAIN } from "@src/utils/constants"
import BookingFormV2 from "@src/components/form-tour-booking-request-v2"
import BookingBanner from "@src/components/booking-banner"
import TourOperatorReviewsService from "@src/services/reviews/tour-operator-reviews"

const StyledResponsiveColumns = styled(ResponsiveColumns)`
  & > section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

const MobileBookingDetails = styled.div`
  & > div.booking-header.mobile {
    display: none;

    @media only screen and (${props => props.theme.screen.small.max}) {
      display: block;
      padding: 2vh 3vw;
    }
  }
`

const PlaceholderDiv = styled.div`
  font-family: "system-ui";
  padding: 0 0;
  & #tour-booking-terms-modal {
    ul {
      padding-left: 20px;
    }
  }
  & > form {
    & > div {
      &:not(#tour-booking-terms-modal) {
        display: block;
      }
    }
  }
  @media only screen and (${props => props.theme.screen.small.max}) {
    & > div.booking-header.desktop {
      display: none;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div.booking-header.desktop {
      width: 656px;
    }
    & > form {
      & > div {
        // First is the div with all the forms
        &:first-of-type {
          & > * {
            width: 656px;
          }
          & > div {
            &.narrow {
              width: 320px;
            }
            &.left {
              position: absolute;
            }
            &.right {
              left: 336px;
            }
          }
        }
        // T&C
        &:last-of-type {
          width: 656px;
        }
      }
    }
  }

  @media only screen and (${props =>
      props.theme.screen.medium.min}) and (${props =>
      props.theme.screen.medium.max}) {
    & > div.booking-header.desktop {
      width: 575px;
    }
    & > div.booking-header.mobile {
      display: none;
    }
    & > form {
      & > div {
        // First is the div with all the forms
        &:first-of-type {
          & > * {
            width: 575px;
          }
          & > div {
            &.narrow {
              width: 280px;
            }
            &.right {
              left: 296px;
            }
          }
        }
        // T&C
        &:last-of-type {
          width: 575px;
        }
      }
    }
  }
`
const StyledTourDetails = styled.section`
  min-height: 125px;
  & h2 {
    text-align: center;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    position: sticky;
    top: ${props => props.theme.header.medium.height}px;
    max-height: calc(100vh - ${props => props.theme.header.medium.height}px);
    overflow-y: auto;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    top: ${props => props.theme.header.large.height}px;
    max-height: calc(100vh - ${props => props.theme.header.large.height}px);
  }
`
const StyledPriceAvailability = styled(PriceAvailability)`
  & > .promo-points {
    display: none;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & > .promo-points {
      display: block;
    }
  }
`

const CaveatText = styled.div`
  font-family: "system-ui";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 2vh;
  align-items: center;
  text-align: left;
  border-radius: 0.625vw;
  padding: 20px;
  padding-left: 80px;
  margin-top: 1vh;
  background: url("/icons/booking.png") no-repeat 2vw 2.5vh;
  background-color: #fff5c7;
  background-size: 32px 32px;
`

const contentColumns = {
  medium: {
    sideWidth: 350,
    spacing: 20,
  },
  large: {
    sideWidth: 400,
    spacing: 20,
  },
  desktop: {
    sideWidth: 400,
    spacing: 20,
  },
}

const TourBookingPage = ({ data, params }) => {
  const {
    tenAdventures: {
      page: {
        images: { header: pageHeaderImage },
        seo: pageSeo,
      },
      termsPage,
    },
  } = data

  const [loaded, setLoaded] = useState(false)
  const [tourDetails, setTourDetails] = useState(null)
  const [headerImage, setHeaderImage] = useState(null)

  const tourName = (params && params.tourPath) || ""
  useEffect(() => {
    if (tourDetails && tourDetails.images && tourDetails.images.feature) {
      setHeaderImage(tourDetails.images.feature)
    }
  }, [tourDetails, setHeaderImage])

  useEffect(() => {
    if (!params.tourPath) {
      setHeaderImage(pageHeaderImage)
      return
    }

    // Retrieve tour data
    TourContent.getTourBySlug(params.tourPath)
      .then(tourData => {
        if (tourData) {
          // Retrieve live tour ratings
          TourOperatorReviewsService.getOperatorRatings(
            tourData.tourOperator.id
          )
            .then(ratingsData => {
              tourData.tourOperator.reviews.counts.total =
                ratingsData.counts.total
              tourData.tourOperator.reviews.overall = ratingsData.items[0][1]
            })
            .then(() => setTourDetails(tourData))
            .catch(error => {
              console.log(error)
              // If there are any issues with the ratings API, still show static ratings
              setTourDetails(tourData)
            })
        } else {
          setHeaderImage(pageHeaderImage)
        }
      })
      .catch(() => {
        setHeaderImage(pageHeaderImage)
      })
      .finally(() => {
        GTM.dataLayerPushPageInfo({
          template: "booking-request-tour",
        })
        setLoaded(true)
      })
  }, [params.tourPath, setHeaderImage, pageHeaderImage])
  return loaded === 404 ? (
    <ErrorPage404 />
  ) : (
    <PrimaryLayout variant="gray">
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          // Canonical set to most popular tour booking page
          canonical: `${SITE_DOMAIN}/bookings/tour/complete-tour-du-mont-blanc-trek/`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, pageSeo.metaRobotsNoindex],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <MobileBookingDetails>
        <div className="booking-header mobile">
          <h1 className="booking-title">Your booking details</h1>
          <p>
            {`Travel is too important to not get exactly what you want. By providing us with your booking details, you'll be taking your first step towards confirming the trip of a lifetime. We can't wait to get to work on your tour booking!`}
          </p>
        </div>
      </MobileBookingDetails>
      <BookingBanner></BookingBanner>

      <StyledResponsiveColumns variant="right" columns={contentColumns}>
        <StyledTourDetails>
          {!loaded ? (
            <>
              <h2>Loading tour availability...</h2>
              <Spinner align="center" />
            </>
          ) : (
            tourDetails && (
              <StyledPriceAvailability
                tourDuration={tourDetails.duration}
                difficulty={tourDetails.difficulty}
                tourIcons={tourDetails.tourIcons}
                price={tourDetails.price}
                discount={tourDetails.price.discount}
                tourItinerary={
                  tourDetails.tourItinerary
                    ? [
                        {
                          label: "Starts",
                          value: tourDetails.itinerary.locationStart,
                        },
                        {
                          label: "Ends",
                          value: tourDetails.itinerary.locationEnd,
                        },
                      ]
                    : undefined
                }
                promotionPoints={false}
                tourImage={headerImage}
                tourStartLocation={tourDetails.itinerary.locationStart}
                tourName={tourDetails.title}
                tourLink={"/tour/" + params.tourPath + "/"}
                reviewsSummary={{
                  rating: tourDetails.tourOperator.reviews?.overall,
                  total: tourDetails.tourOperator.reviews?.counts.total,
                }}
              />
            )
          )}
          <CaveatText>
            You won&apos;t be asked for a credit card, and a booking is not
            being completed at this point.
          </CaveatText>
        </StyledTourDetails>
        <PlaceholderDiv>
          <div className="booking-header desktop">
            <h2 className="h1 booking-title">Your booking details</h2>
            <p>
              {`Travel is too important to not get exactly what you want. By providing us with your booking details, you'll be taking your first step towards confirming the trip of a lifetime. We can't wait to get to work on your tour booking!`}
            </p>
          </div>
          <BookingFormV2
            product={{
              id: `tour/${tourName}`,
              price: tourDetails ? tourDetails.price : undefined,
              title: tourDetails ? tourDetails.title : undefined,
              itinerary: tourDetails ? tourDetails.itinerary : undefined,
              durationNights: tourDetails?.duration
                ? tourDetails.duration.nights
                : undefined,
              operator: tourDetails ? tourDetails.tourOperator.name : undefined,
            }}
            bookingTerms={termsPage}
            availability={
              tourDetails && {
                daysOfWeek: tourDetails.dayofweekPrices?.prices?.length
                  ? tourDetails.dayofweekPrices.prices.reduce((carry, item) => {
                      if (item && item.day && item.price && item.price.amount) {
                        carry.push(weekdayToNumber(item.day))
                      }
                      return carry
                    }, [])
                  : undefined,
                fixedDates: tourDetails.fixedDates?.length
                  ? tourDetails.fixedDates.map(item => ({
                      start: item?.start?.date,
                      finish: item?.finish?.date,
                    }))
                  : undefined,
                months: tourDetails.tourMonths,
              }
            }
          />
        </PlaceholderDiv>
      </StyledResponsiveColumns>
    </PrimaryLayout>
  )
}
TourBookingPage.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        images: PropTypes.shape({
          header: PropTypes.shape({ ...ImagePropTypes }),
        }),
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      termsPage: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
      tourOperator: PropTypes.shape({
        id: PropTypes.string,
        reviews: PropTypes.shape({
          overall: PropTypes.number,
          counts: PropTypes.shape({
            total: PropTypes.number,
          }),
        }),
      }),
    }),
  }),
  params: PropTypes.object,
}
export default TourBookingPage
export const query = graphql`
  query ($id: ID!, $termsId: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        images {
          header {
            ...TenAdventuresGQL_ImageSizesQuery
          }
        }
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
      termsPage: page(id: $termsId, idType: DATABASE_ID) {
        title
        content
      }
    }
  }
`
